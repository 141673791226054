/**
 * Renders the Login component, which provides a login functionality using the Microsoft Authentication Library (MSAL).
 * The component initializes a `PublicClientApplication` instance with the necessary configuration, and provides
 * `login` and `logout` functions to handle the authentication process.
 * The login functionality is triggered by clicking the "Login" button, which opens a popup window for the user to authenticate.
 * The logout functionality is triggered by calling the `logout` function, which clears the user's authentication state.
 */
import React, { useState } from "react";
import { loginRequest } from "../auth/authConfig";
import { callMsGraph } from "../auth/graph";
import { useMsal } from "@azure/msal-react";

const Login = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  // const { instance: instance } = useMsal();

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) => setGraphData(response));
      });
  }
  const handleLogin = (loginType: any) => {
    console.log("@azure/msal-react instance: ", instance);

    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  const handleLogout = (logoutType: any) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  return (
    <div className="login">
      <div className="popup">
        <div className="header">Welcome Back</div>
        <div className="content">
          <button className="btn btn-form" onClick={() => handleLogin("popup")}>
            Connect
          </button>
        </div>
      </div>
    </div>
  );
};
export default Login;
