import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

const FormInput = ({ name, value, onChange, required, type = "text" }: any) => {
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef<any>(null);

  const onFocusBlur = (e: any) => {
    if (e.type === "focus" || e.target.value.length > 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const assignValue = (e: any) => {
    onChange(e.target.value);
  };

  const focus = () => {
    inputRef.current.focus();
  };

  return (
    <div className={`form-group textinput ${isActive ? "active" : ""}`}>
      <label className="label-control">
        {required ? (
          <span className="label-text">
            {name}
            <i>*</i>
          </span>
        ) : (
          <span className="label-text">{name}</span>
        )}
      </label>
      <input type={type} name={name} value={value} className="form-control" ref={inputRef} onFocus={onFocusBlur} onBlur={onFocusBlur} onChange={assignValue} />
    </div>
  );
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
};

FormInput.defaultProps = {
  type: "text",
};

export default FormInput;
