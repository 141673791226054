import React from "react";
import Routers from "./router";

import "./css/main.scss";

function App() {
  return <Routers />;
}

export default App;
