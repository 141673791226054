import { Link } from "react-router-dom";

const OneMeeting = ({ id, roomName, roomId, title, host, timeStart, timeEnd, status, isHost, toggleMeetingStatus, email }: any) => (
  <div className={`meeting ${status ? "available" : "cancel"}`}>
    <p>
      <Link to={`/look/${roomId}`}>{roomName}</Link>
      <span className="time">
        {timeStart} - {timeEnd}
      </span>
    </p>
    <h4 className="title">{title}</h4>
    <p className="author">
      {host === email ? (
        <strong>
          <span>Email: </span>
          {host}
        </strong>
      ) : (
        <>
          <span>Email: </span>
          {host}
        </>
      )}
    </p>
    {isHost && (
      <a className="attend" onClick={() => toggleMeetingStatus(id, !status)}>
        {status ? "Annuler" : "Réserver"}
      </a>
    )}
  </div>
);

export default OneMeeting;
