import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import FormInput from "../FormInput";
import FormDropdown from "../FormDropdown";
import Success from "../Success";
import { addMeetings, getRooms } from "../../api";
import useProfileData from "../UseProfileData";

const ROOMS = [
  {
    _id: 1,
    value: "Salle 1",
  },
  {
    _id: 2,
    value: "Salle 2",
  },
];
const INTERVALS = ["08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00"];

const BookForm = ({ isOpened, closeBookForm, room }: any) => {
  const profileData: any = JSON.parse(localStorage.getItem("user") || "{}");

  const [state, setState] = useState({
    title: "",
    location: "",
    startT: "",
    endT: "",
    startIndex: 0,
    roomIndex: 0,
  });
  const [selected, setSelected] = useState<Date>();
  const [rooms, setRooms] = useState<any>([]);
  const [endArr, setEndArr] = useState(INTERVALS);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roomsData: any[] = await getRooms();
        if (roomsData && roomsData.length > 0) setRooms(roomsData);
      } catch (err) {
        console.log("Failed to fetch users");
      }
    };
    fetchData();
  }, []);

  const close = () => {
    setState({
      title: "",
      location: "",
      startT: "",
      endT: "",
      startIndex: 0,
      roomIndex: 0,
    });
    setSuccess(false);
    closeBookForm();
  };

  const handleReservation = async () => {
    try {
      const day = selected?.toISOString().split("T")[0];
      const startTime = new Date(`${day}T${state?.startT}`).toISOString();
      const endTime = new Date(`${day}T${state?.endT}`).toISOString();
      const newMeeting = {
        email: profileData?.mail,
        room: rooms[state?.roomIndex]?._id,
        title: state?.title,
        startT: startTime,
        endT: endTime,
      };
      const add: any[] = await addMeetings(newMeeting);
      setSuccess(true);
      setTimeout(close, 1500);
    } catch (err) {
      console.log("Failed to fetch users");
    }
  };

  const mapTime2Obj = (timeArr: any) => {
    return timeArr.map((el: any) => ({ value: el }));
  };

  const handleTitle = (title: string) => {
    setState({ ...state, title });
  };

  const handleLocation = (location: string) => {
    const values = location?.split(",");
    setState({ ...state, location: values?.[0] || "", roomIndex: parseInt(values?.[1] || "0") });
  };

  const handleStartT = (startT: string) => {
    const values = startT?.split(",");
    setState({ ...state, startT: values?.[0] || "", startIndex: parseInt(values?.[1] || "0") + 1 });
  };

  const handleEndT = (endT: string) => {
    setState({ ...state, endT });
  };
  const isEmpty = (val: any) => val === null || val === "";
  const allFieldsFilled = !Object.values(state).some(isEmpty);

  return (
    <div className={`bookform ${isOpened ? "opened" : ""}`}>
      <div className="modal-backdrop" onClick={closeBookForm}></div>
      <div className="popup">
        <div className="header">
          Planifier une réunion
          <a href="#" className="close" onClick={closeBookForm}></a>
        </div>
        <div className="content">
          {success ? (
            <Success />
          ) : (
            <div>
              <FormInput name="Title" value={state?.title} required={true} onChange={handleTitle} />
              <div className="inputs-group">
                <FormDropdown name="Salle" value={"Salle"} disabled={state?.title?.length >= 2 ? false : true} ddList={rooms} onChange={handleLocation} startIndex={0} />
                <FormDropdown name="Heure de début" value={"HH:MM"} disabled={state?.title?.length == 0 ? true : state?.location?.length >= 1 ? false : true} ddList={mapTime2Obj([...INTERVALS.slice(0, -1)])} onChange={handleStartT} startIndex={0} />
                <FormDropdown name="L'heure de fin" value={"HH:MM"} disabled={state?.title?.length == 0 ? true : state?.location?.length >= 1 ? false : true} ddList={mapTime2Obj(endArr)} onChange={handleEndT} startIndex={state?.startIndex} />
              </div>
              {allFieldsFilled && <DayPicker defaultMonth={new Date()} showOutsideDays mode="single" disabled={{ dayOfWeek: [0, 6] }} selected={selected} onSelect={setSelected} fromDate={new Date()} />}
              <input type="submit" value="Claim Room" disabled={allFieldsFilled && selected != undefined ? false : true} className="btn btn-form" onClick={handleReservation} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BookForm.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeBookForm: PropTypes.func.isRequired,
  room: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default BookForm;
