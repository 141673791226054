import React from "react";
import { Link, NavLink } from "react-router-dom";

const Header = ({ openBookForm }: any) => (
  <nav className="navbar navbar-inverse navbar-fixed-top">
    <div className="container">
      <div className="navbar-header">
        <Link className="navbar-brand icon" to="/"></Link>
      </div>
      <div className="book-btn">
        <button className="btn btn-danger" onClick={(e) => openBookForm()}>
          Réserver
        </button>
      </div>
    </div>
  </nav>
);

export default Header;
