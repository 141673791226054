import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Header from "../components/Header";
import MeetingsList from "../components/MeetingList";
import BookForm from "../components/BookForm";
import { getMeetingByRoomId, getMeetingById, updateMeetingStatus } from "../api";

const Look = () => {
  const [scrolled, setScrolled] = useState(false);
  const { id } = useParams();
  const profileData: any = JSON.parse(localStorage.getItem("user") || "{}");
  const [formIsOpened, setFormIsOpened] = useState(false);
  const [timeline, setTimeline] = useState([]);
  const [roomInfo, setRoomInfo] = useState({ photo: "", name: "", value: "" });

  const roomId = id;

  const fetchRoomInfo = useCallback(async () => {
    if (roomId !== undefined) {
      try {
        const roomsData: any = await getMeetingById(roomId);
        console.log("roomsData :::: ", roomsData);

        setRoomInfo(roomsData);
        // if (roomsData && roomsData.length > 0) setRooms(roomsData);
      } catch (err) {
        console.log("Failed to fetch users");
      }
    } else {
      setRoomInfo({ photo: "", name: "", value: "" });
    }

    // axios.get(`/api/meetings/roominfo?roomId=${roomId}`).then((res) => setRoomInfo(res.data[0]));
  }, [roomId]);

  const fetchMeetingsList = useCallback(async () => {
    // axios.get(`/api/meetings?roomId=${roomId}`).then((res) => setTimeline(res.data));
    try {
      const data: any = await getMeetingByRoomId({ roomId: roomId, email: profileData?.mail });

      setTimeline(data);
    } catch (err) {
      console.log("Failed to fetch users");
    }
  }, [roomId]);

  useEffect(() => {
    fetchRoomInfo();
    fetchMeetingsList();
  }, [fetchRoomInfo, fetchMeetingsList]);

  useEffect(() => {
    const handleScroll = (event: any) => {
      let top = event.srcElement.body.scrollTop;

      //room-photo height (200px) - header (50px)
      if (top > 150) {
        if (!scrolled) setScrolled(true);
      } else {
        if (scrolled) setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);

  const toggleMeetingStatus = async (id: string, status: boolean) => {
    try {
      const data: any = await updateMeetingStatus(id, status);
      fetchMeetingsList();
    } catch (err) {
      console.log("Failed to fetch users");
    }
  };

  const toggleBookForm = () => {
    setFormIsOpened(!formIsOpened);
  };

  if (!roomInfo) return <div className="loader"></div>;

  console.log("roomInforoomInforoomInforoomInforoomInfo :::: ", roomInfo);

  return (
    <div className="look">
      <div className={`wrap ${scrolled ? "scrolled" : ""}`}>
        <Header openBookForm={toggleBookForm} />

        <div className="timeline">
          <div className="timeline-header">
            <div className="container">{roomId ? <h3 className="title">Room Schedule | {roomInfo?.value}</h3> : <h3 className="title">Horaires</h3>}</div>
          </div>

          {timeline && Array.isArray(timeline) && <MeetingsList meetings={timeline} toggleMeetingStatus={toggleMeetingStatus} email={profileData?.mail} />}
        </div>
      </div>

      <BookForm isOpened={formIsOpened} closeBookForm={toggleBookForm} />
    </div>
  );
};

export default Look;
