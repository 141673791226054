import { useState, useEffect } from "react";
import Header from "../components/Header";
import BookForm from "../components/BookForm";
import OneRoom from "../components/OneRoom";
import { getRooms } from "../api";

const Rooms = () => {
  const [formIsOpened, setFormIsOpened] = useState(false);
  const [activeRoom, setActiveRoom] = useState({ id: "", name: "" });
  const [rooms, setRooms] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roomsData: any[] = await getRooms();
        if (roomsData && roomsData.length > 0) setRooms(roomsData);
      } catch (err) {
        console.log("Failed to fetch users");
      }
    };
    fetchData();
  }, []);

  const toggleBookForm = (roomId = "", roomName = "") => {
    setActiveRoom({ id: roomId, name: roomName });
    setFormIsOpened(!formIsOpened);
  };

  const handleFormOpen = () => {
    setFormIsOpened(!formIsOpened);
  };

  return (
    <div className="book">
      <Header openBookForm={toggleBookForm} />
      <div className="container-fluid content">
        <div className="row room-list">
          {rooms.map((el: any, i: number) => (
            <OneRoom photo={el.photo || ""} key={`room-${i}`} name={el.value} roomId={el._id} meeting={el.value} callBookForm={() => toggleBookForm(el._id, el.value)} />
          ))}
        </div>
      </div>
      <BookForm isOpened={formIsOpened} closeBookForm={handleFormOpen} room={activeRoom} />
    </div>
  );
};

export default Rooms;
