import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Header from "../components/Header";
import MeetingsList from "../components/MeetingList";
import BookForm from "../components/BookForm";
import { getMeetingAll, getMeetingById, updateMeetingStatus } from "../api";

const Look = () => {
  const [scrolled, setScrolled] = useState(false);
  const { id } = useParams();
  const profileData: any = JSON.parse(localStorage.getItem("user") || "{}");
  const [formIsOpened, setFormIsOpened] = useState(false);
  const [timeline, setTimeline] = useState([]);

  const fetchMeetingsList = useCallback(async () => {
    try {
      const data: any = await getMeetingAll({ email: profileData?.mail });

      setTimeline(data);
    } catch (err) {
      console.log("Failed to fetch users");
    }
  }, []);

  useEffect(() => {
    fetchMeetingsList();
  }, [fetchMeetingsList]);

  useEffect(() => {
    const handleScroll = (event: any) => {
      let top = event.srcElement.body.scrollTop;

      //room-photo height (200px) - header (50px)
      if (top > 150) {
        if (!scrolled) setScrolled(true);
      } else {
        if (scrolled) setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);

  const toggleMeetingStatus = async (id: string, status: boolean) => {
    try {
      const data: any = await updateMeetingStatus(id, status);
      fetchMeetingsList();
    } catch (err) {
      console.log("Failed to fetch users");
    }
  };

  const toggleBookForm = () => {
    setFormIsOpened(!formIsOpened);
  };

  console.log("roomInforoomInforoomInforoomInforoomInfo :::: ", timeline);

  return (
    <div className="look">
      <div className={`wrap ${scrolled ? "scrolled" : ""}`}>
        <Header openBookForm={toggleBookForm} />

        <div className="timeline">
          <div className="timeline-header">
            <div className="container">{<h3 className="title">Horaires</h3>}</div>
          </div>

          {timeline && Array.isArray(timeline) && <MeetingsList meetings={timeline} toggleMeetingStatus={toggleMeetingStatus} email={profileData?.mail} />}
        </div>
      </div>

      <BookForm isOpened={formIsOpened} closeBookForm={toggleBookForm} />
    </div>
  );
};

export default Look;
