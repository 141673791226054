import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import OneMeeting from "../OneMeeting";

const MeetingsList = ({ meetings, toggleMeetingStatus, email }: any) => {
  return (
    <div className="timeline-body">
      <div className="container">
        {meetings?.map((el: any, i: number) => {
          console.log("el", el?.status);

          return (
            <div key={`section-${i}`}>
              {el.diff !== meetings[i - 1]?.diff && (
                <div className="date text-center">
                  <p>{moment(el.startTime).format("MMMM Do")}</p>
                </div>
              )}
              <OneMeeting
                key={`meeting-${i}`}
                id={el._id}
                roomId={el.room._id}
                roomName={el.room.value}
                title={el.title}
                host={el.email}
                timeStart={moment(el.startTime).format("HH:mm")}
                timeEnd={moment(el.endTime).format("HH:mm")}
                status={el.status}
                isHost={el.isHost}
                toggleMeetingStatus={toggleMeetingStatus}
                email={email}
              />
            </div>
          );
        })}
      </div>
      <br />
    </div>
  );
};

export default MeetingsList;
