import React from "react";
import { Link } from "react-router-dom";

const Home = () => (
  <div className="pick">
    <Link id="book" to="/rooms">
      <span className="text">
        <span className="heading">Les salles</span>
        <span>Liste des salles</span>
        <i className="icon"></i>
      </span>
    </Link>
    <Link id="look" to="/events">
      <span className="text">
        <span className="heading">Evénements</span>
        <span>Evénements à venir</span>
        <i className="icon"></i>
      </span>
    </Link>
  </div>
);

export default Home;
