// api.ts
import axios from "axios";

// const apiUrl = "http://localhost:8008";
const apiUrl = "http://10.135.0.122:10500";
// console.log("apiUrl :::: ", apiUrl);

export const getRooms = async (): Promise<any[]> => {
  const response = await axios.get<any[]>(`${apiUrl}/api/rooms`);
  return response.data;
};

export const addMeetings = async (newMeeting: any): Promise<any[]> => {
  const response = await axios.post<any[]>(`${apiUrl}/api/meetings`, newMeeting);
  return response.data;
};

export const getMeetingById = async (roomId: string): Promise<any[]> => {
  const response = await axios.get<any[]>(`${apiUrl}/api/meetings/roominfo?roomId=${roomId}`);
  return response.data;
};

export const getMeetingByRoomId = async ({ roomId, email }: any): Promise<any[]> => {
  const response = await axios.get<any[]>(`${apiUrl}/api/meetings?roomId=${roomId}&email=${email}`);
  return response.data;
};

export const getMeetingAll = async ({ email }: any): Promise<any[]> => {
  const response = await axios.get<any[]>(`${apiUrl}/api/meetings/all?email=${email}`);
  return response.data;
};

export const updateMeetingStatus = async (id: string, status: boolean): Promise<any[]> => {
  const response = await axios.get<any[]>(`${apiUrl}/api/meetings/status?id=${id}&status=${status}`);
  console.log("response :::: ", response);

  return response.data;
};
