import React, { useEffect } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { loginRequest } from "./auth/authConfig";
import { callMsGraph } from "./auth/graph";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Rooms from "./pages/Rooms";
import Events from "./pages/Events";
import Look from "./pages/Look";

const routerConfig = [
  {
    path: "/",
    element: <></>,
    children: [
      { path: "/", access: [], redirect: "/login", element: <Home /> },
      { path: "/login", access: [], redirect: "/", element: <Login /> },
      { path: "/rooms", access: [], redirect: "/", element: <Rooms /> },
      { path: "/events", access: [], redirect: "/", element: <Events /> },
      { path: "/look/:id", access: [], redirect: "/", element: <Look /> },
    ],
  },
];

const Routers = () => {
  const isAuthenticated = useIsAuthenticated();
  console.log("@azure/msal-react instance: ", isAuthenticated);

  const { instance, accounts } = useMsal();
  console.log("@azure/msal-react instance: ", instance);
  console.log("@azure/msal-react accounts: ", accounts);

  const RequestProfileData = async () => {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    await instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) => localStorage.setItem("user", JSON.stringify(response)));
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData();
    }
  }, [isAuthenticated]);

  // console.log("isAuthenticated", isAuthenticated);

  const renderRoute = ({ path, redirect, element }: any) => {
    if (path === "/login") {
      return isAuthenticated ? <Navigate to="/" /> : element;
    }
    if (path === "/") {
      return isAuthenticated ? element : <Navigate to="/login" />;
    }
    if (path === "/events" || path === "/look/:id") {
      return isAuthenticated ? element : <Navigate to="/login" />;
    }
    return element;
  };

  return (
    <Router>
      <Routes>{routerConfig.map(({ path, children }) => children && children.map(({ path, redirect, element }) => <Route key={path} path={path} element={renderRoute({ path, redirect, element })} />))}</Routes>
    </Router>
  );
};

export default Routers;
