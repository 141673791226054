import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";

const FormDropdown = ({ name, value, disabled, ddList, onChange, startIndex }: any) => {
  return (
    <div className="form-group">
      <label>
        <span className="label-text">{name}</span>
      </label>
      <Form.Select size="sm" disabled={disabled} onChange={(e) => onChange(e?.target?.value)}>
        <option disabled selected>
          {value}
        </option>
        {ddList.slice(startIndex).map((el: any, index: any) => (
          <option key={index} value={startIndex === 0 ? `${el.value},${index}` : el?.value}>
            {el.value}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default FormDropdown;
